import { createGlobalStyle } from 'styled-components';
import SpaceGrotesk from '../../fonts/SpaceGrotesk.woff2';
import theme from './theme';

const GlobalStyles = createGlobalStyle`

    @font-face {
    font-family: 'Space Grotesk';
    src: url(${SpaceGrotesk}) format('woff2')
    }    

    body{
        overflow-x: hidden;
        margin: 0; 
        padding: 0; 
        box-sizing: border-box; 
        background-color: ${theme.common.white};
    }

    ul {
        list-style-type: none;
        font-weight: 300;
        font-family: 'Space Grotesk', sans-serif;
        font-size: 1.5rem;
        color: ${theme.common.grey};
    }

    h1 {
        margin: 20px 0px 20px 0px;
        font-weight: 300;
        font-family: 'Space Grotesk', sans-serif;
        font-size: 4rem;
        color: ${theme.common.white};
        @media only screen and (max-width: 500px){
            font-size: 3rem;
        }
    }
    h2 {
        margin: 0px 0px 0px 0px;
        font-family: 'Space Grotesk', sans-serif;
        font-size: 3.75rem;
        font-weight: 300;
        color: ${theme.common.black};
        @media only screen and (max-width: 500px){
            font-size: 2.5rem;
        }
    }
    h3 {
        margin: 30px 0px 40px 0px;
        font-family: 'Space Grotesk', sans-serif;
        font-size: 2.2rem;
        font-weight: 300;
        color: ${theme.common.grey};
        @media only screen and (max-width: 500px){
            font-size: 1.5rem;
        }
    }
    h4 {
        font-family: 'Space Grotesk', sans-serif;
        font-size: 2.2rem;
        font-weight: 300;
        color: ${theme.common.black};
        @media only screen and (max-width: 500px){
            font-size: 1.5rem;
        }
    }
    h5 {
        font-family: 'Space Grotesk', sans-serif;
        font-size: 1.8rem;
        font-weight: 300;
        color: ${theme.common.black};
    }
    p {
        font-family: 'Space Grotesk', sans-serif;
        font-size: 1.2rem;
        font-weight: 300;
        color: ${theme.common.black};
    }
    button {
        font-family: 'Space Grotesk', sans-serif;
        font-weight: 300;
    }
    a {
        font-family: 'Space Grotesk', sans-serif;
        font-weight: 300;
    }
    label{
        font-family: 'Space Grotesk', sans-serif;
        font-size: 1.2rem;
        font-weight: 300;
        color: ${theme.common.black};
    }
    #gatsby-focus-wrapper:nth-child(2){
        display:none;
    }
`;

export default GlobalStyles;
